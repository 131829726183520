import * as React from 'react'
import NavHeader from '../../components/navHeaderEn'
import SiteFooter from '../../components/footerEn'
import PageTitle from '../../components/pageTitle'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import * as newsStyle from '../../styles/news.module.scss'

const News = ({ data }) => {
  const ExtractData = data.allMdx.nodes
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="News"/>
      <div className="mainContainer">
       
          {
          ExtractData.map(node=> (
            <section>
            <div className="sectionContainer" key={node.id}>
            <h2>{node.frontmatter.title}</h2>
            <p>{node.frontmatter.description}</p>
            <Link to={"/" + node.slug} className={newsStyle.newsButton}>read more</Link>
            <div className="section-bbl-sp-item" id="positive"></div>
            <div className="section-ln-sp-item"></div>
          </div>
        </section>
        ))
        }
      </div>
    <SiteFooter/>
    </main>
  )
}



export const query = graphql`
{
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {categories: {eq: "en"}}}
  ) {
    nodes {
      frontmatter {
        title
        description
        categories
        date
      }
      id
      slug
    }
  }
}
`
export default News
